const Envir = {
   // capacitor.config.json settings :
   // "appId": "com.crytalprism.medeata" - iOS
   // "appId": "com.medeata.app" - android
   //
   // production settings :
   //             the below setting is for iOS app:
   //             ENV : 'prod'
   //             package.json :  "homepage": "https://medeata.com",
   // production settings :
   //             ENV : 'production'
   //             package.json :  "homepage": "https://medeata.com",
   // qa settings :
   //             ENV : 'qa'
   //             package.json :  "homepage": "https://mediotate.com",
   // dev settings :
   //             ENV : 'dev'
   //             package.json :  "homepage": SHOULD BE REMOVED!,
   ENV : 'production', 
   IS_ACE : false,
   LOGIN_FOLDER : '/',
   DB_JSON : 'ON',
   // rotation could be checked in dev
   ANDROID_IOS_APP : false,
   ANDROID_APP : false,
   iOS_APP : false,
   APP_DOMAIN : 'localhost',
   DEV_SERVER : false,
   EXPRESS_SERVER : false
}

if (Envir.ENV === 'qa') {
   Envir.APP_DOMAIN = 'mediotate.com';
}
else if ((Envir.ENV === 'prod') || ((Envir.ENV === 'production'))) {
   Envir.APP_DOMAIN = 'medeata.com';
   Envir.EXPRESS_SERVER = true;
}

export default Envir;