import Const from '../components/Const';
import { getLoadingImageObserver } from '../utils/SessionUtils.js';
import  { isDesktop, isiPhone } from '../utils/AppUtils.js';

export const HOME = 'Home';
export const LOGIN_USER = 'ProfileOfLoginUser';
export const ANY_USER = 'ProfileOfAnyUser';
export const COMMENTS = 'GetComments';
export const SEARCH = 'UniversalSearch';

export const HOME_PRPOP = 'home';
export const LOGIN_USER_PROP = 'loginUser';
export const ANY_USER_PROP = 'anyUser'; 
export const SEARCH_PROP = 'search'; 

let scrollObj = {
    home : {
      postsScreenPosition : null
    },
    loginUser : {
      postsScreenPosition : null,
      imgsScreenPosition : null,
    },
    anyUser : {
      postsScreenPosition : null,
      imgsScreenPosition : null,
      userId : null  
    },
    comments : {
      commentsScreenPosition : null
    },
    search : {
      searchScreenPosition : null
    },
    componentNameClass : null,
    currentScrollListener : null
}

export function addScrollListener(that) {
  removeScrollListener();
  window.onscroll = listenToScroll;
  scrollObj.currentScrollListener = listenToScroll;
  scrollObj.componentNameClass = that;
  scrollObj.componentName = that.className;
  cleanAfterAnyUser(that);
}

export function cleanAfterAnyUser(that) {
  let scrollObjProperty = getComponentPropertyFromClassName(that);

  if (scrollObjProperty === ANY_USER_PROP) {
    // cleaning after previous any user 
    unsetPostsLoadinOrPostsEndOption(that, Const.POSTS_ARE_LOADING); 
    unsetPostsLoadinOrPostsEndOption(that, Const.END_OF_POSTS); 
    scrollObj.anyUser.postsScreenPosition = null;
    scrollObj.anyUser.imgsScreenPosition = null;
  }
}

export function removeScrollListener() {
  let observer = getLoadingImageObserver();

  observer.disconnect();
  window.onscroll = null;
}

export function listenToScroll() {
  let that = scrollObj.componentNameClass;
  let observer;
  let loadingImg;
 
  if (that.className !== COMMENTS) {
    loadingImg = that.loadingImgRef.current;
    if (loadingImg) {
      observer = getLoadingImageObserver();
      observer.observe(loadingImg);
    }
  }
  if (that) {
    storecomponentNameScreenPosition(that);
  }
}

export async function bottomOfPage() {
  let that = scrollObj.componentNameClass;
  let scrollObjProperty;
 
  if (that.loadMorePostsIsNeeded()) {
    scrollObjProperty = getComponentPropertyFromClassName(that);
   
    if (!scrollObj[scrollObjProperty].hasOwnProperty(Const.POSTS_ARE_LOADING)  && 
          !scrollObj[scrollObjProperty].hasOwnProperty(Const.END_OF_POSTS)) {
      setPostsLoadinOrPostsEndOption(that, Const.POSTS_ARE_LOADING);
      await that.getPartialUserJson();
    }
  }
}

export function setPostsLoadinOrPostsEndOption(that, option) {
  let scrollObjProperty = getComponentPropertyFromClassName(that);

  scrollObj[scrollObjProperty][option] = true;  
}

export function unsetPostsLoadinOrPostsEndOption(that, option) {
  let scrollObjProperty = getComponentPropertyFromClassName(that);
  
  delete scrollObj[scrollObjProperty][option];  
}

export async function scrollToStroredPosition(that) {
  let screenPosition = getcomponentNameScreenPosition(that);

  if (!screenPosition) {
    screenPosition = 0;
  }
  window.scrollTo(0, screenPosition); // this is async by nature but not programatically
}

export async function scrollToStroredPositionConditional(that) {
  let loginUser = that.state.loginUser;

  if (scrollObj.componentNameClass && (scrollObj.componentNameClass.className === COMMENTS)) {
    // we just came from getComments() and need to avoid extta scroll bug for home and anyUser
    // (loginUser does it in componentDidUpdate() method anyway).
    // So we force componentDidUpdate() and invoke scrollToStroredPosition() from there
    loginUser.randomNum = Math.random(); 
		that.setState({ loginUser : loginUser }); 
  }
  else {
      scrollToStroredPosition(that);
  }
}

export function scrollToTop(that) {
  pageScrollToTop();
  storecomponentNameScreenPosition(that);
}

export function pageScrollToTop() {
  window.scrollTo(0, 0);
}

export function pageScrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
}

export function scrollToTopForProfileUser(that) {
  // if (that.constructor.name === LOGIN_USER) { // looks like this is not working in production build
  if (that.className === LOGIN_USER) {
    scrollToTop(that);
  }
  else {
    scrollObj.loginUser.postsScreenPosition = 0;
  }
}

export function storecomponentNameScreenPosition(that) { 
  let screenPosition = window.scrollY;
  let componentName = that.className;
 
  switch (componentName) {
        case HOME:
                            scrollObj.home.postsScreenPosition = screenPosition;
                            break;
        case LOGIN_USER:
                            if (that.state.postView) {
                              scrollObj.loginUser.postsScreenPosition = screenPosition;
                            }
                            else {
                              scrollObj.loginUser.imgsScreenPosition = screenPosition;
                            }
                            break;
        case ANY_USER:
                            if (that.state.hasOwnProperty('fullPersonIFollow') && (that.state.fullPersonIFollow !== null)) {
                              if (that.state.postView) {
                                scrollObj.anyUser.postsScreenPosition = screenPosition;
                              }
                              else {
                                scrollObj.anyUser.imgsScreenPosition = screenPosition;
                              }
                              scrollObj.anyUser.userId = that.state.fullPersonIFollow.userId;
                            }
                            break;
        case COMMENTS:      break;
        case SEARCH:        scrollObj.search.searchScreenPosition = screenPosition;     
                            break;
        default:            ;
  }
}

export function getcomponentNameScreenPosition(that) { 
  let screenPosition = null;
  let componentName = that.className;

  switch (componentName) {
        case HOME:
                            screenPosition = scrollObj.home.postsScreenPosition;
                            break;
        case LOGIN_USER:
                            if (that.state.postView) {
                              screenPosition = scrollObj.loginUser.postsScreenPosition;
                            }
                            else {
                              screenPosition = scrollObj.loginUser.imgsScreenPosition;
                            }
                            break;
        case ANY_USER:
                            if (that.state.hasOwnProperty('fullPersonIFollow') && 
                                  (that.state.fullPersonIFollow !== null) && 
                                      (that.state.fullPersonIFollow.userId === scrollObj.anyUser.userId)) {
                              if (that.state.postView) {
                                screenPosition = scrollObj.anyUser.postsScreenPosition;
                              }
                              else {
                                screenPosition = scrollObj.anyUser.imgsScreenPosition;
                              }
                            }
                            break;
        case COMMENTS:      screenPosition = scrollObj.comments.commentsScreenPosition;
                            break;
        case SEARCH:        screenPosition = scrollObj.search.searchScreenPosition;
                            break;
        default:            screenPosition = null;
  }
  return screenPosition;
}

export function getComponentPropertyFromClassName(that) { 
  let componentName = that.className;
  let property;
 
  switch (componentName) {
        case HOME:
        
                            property = HOME_PRPOP;
                            break;
        case LOGIN_USER:
                            property = LOGIN_USER_PROP;
                            break;
        case ANY_USER:
                            property = ANY_USER_PROP;
                            break;
        case SEARCH:
                            property = SEARCH_PROP;
                              break;
        default:            property = "";
  }
  return property;
}

export async function getImageDimentions(url, callback) {
  let  img = new Image();
  
  img.src = url;
  img.onload = function() { callback(this.height); }
}

export async function getImageDimentionsPromise(url) {
  return new Promise((resolve) => {
    getImageDimentions(url,(successResponse) => {
      resolve(successResponse);
    });
  });
}

export async function setPostImagesHeights(posts) {
  for (let post of posts ) {
    await setPostImagesHeight(post);
  }
}

export async function setPostImagesHeight(post) {
  if (post.hasOwnProperty('postImg')) {
    if (post.imgHeight === 0) {
      post.imgHeight = await getImageDimentionsPromise(post.postImg);
    }
  }
}

export function setPageScroll(desable) {
  removeModaBodylInternalClass();
  if (desable) {
    disablePageScroll();
  }
  else {
    enablePageScroll();
  }
}

export function disablePageScroll() {
  // Get the current page scroll position
  let scrollTop = window.scrollY || document.documentElement.scrollTop;
  let scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  window.onscroll = function() {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

export function enablePageScroll() {
  window.onscroll = scrollObj.currentScrollListener;
}

export function setModalOverflowScroll(that) {
  if (isPageScrollable()) {
    that.overflowScroll = true;
    document.body.style.overflow = 'auto'; // important because after removal of modal internal class, scroll became hidden
  }
  else {
    that.overflowScroll = false;
  }
}

export function isPageScrollable() {
  let scrollable = false;

  if (document.documentElement.scrollHeight > document.documentElement.clientHeight) {
    scrollable = true;
  }
  return scrollable;
}

export function removeModaBodylInternalClass() {
  if (document.body.classList.contains("overflow-y-scroll")) {
    document.body.classList.remove("overflow-y-scroll");
  }
}

export function modalPopUpScrollProcessor(that) {
  let blocker;

  if (isiPhone()) {
    setPageTouchMove(that);
  }
  else {
    blocker = false;
    setPageScroll(!that.state.modal);
    if (blocker && !that.state.modal) {
      setModalOverflowScroll(that);
    }
  }
}

export function imageGaleryScrollProcessor(imagePicked) {
  if (imagePicked) {
    if (isDesktop()) {
      disablePageScroll();
    }
    else {
      startTouchMoveBlocker();;
    }
  }
  else {
    if (isDesktop()) {
      enablePageScroll();
    }
    else {
      stopTouchMoveBlocker();
    }
  }
}

export function messageModalPopUpScrollProcessor(that) {
  let blocker = false;

  setPageScroll(!that.state.messageModal);
  if (blocker && !that.state.messageModal) {
    setModalOverflowScroll(that);
  }
}

export function showNewlyAddedPostOnTop(that) {
  let userDescriptionSection = document.getElementById('main');

  if (userDescriptionSection) {
    window.scrollTo(0, userDescriptionSection.offsetHeight + that.postsContainerRef.current.firstChild.offsetTop);
    storecomponentNameScreenPosition(that);
  }
 
}

export function loadingImage(that) {
  return (
          <div>
              <div  className="">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
              <div ref={that.loadingImgRef} style = {{height : '3px'}}>
              </div>
          </div>
  );
}

export function getSpinner(that, visibilityHidden) {
  return (
    <div id = "spinner" style = { visibilityHidden }> 
      { loadingImage(that) }
    </div>	
  );
}

export function setPageTouchMove(that) {
  let property;

	if (that.state.modal) {
    stopTouchMoveBlocker();
    property = getComponentPropertyFromClassName(that);
    if (property === LOGIN_USER_PROP ) {
      scrollToTop(that);
    }
  }
  else {
    startTouchMoveBlocker();
  }
}

export function startTouchMoveBlocker() {
  window.addEventListener("touchmove", eventBlocker, { passive: false });
}

export function stopTouchMoveBlocker() {
  window.removeEventListener('touchmove', eventBlocker, { passive: false });
}

export function eventBlocker(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
}

export function underlyingComponentPositionProtector(modal, underlyingComponent) {
  if (underlyingComponent) {
    if (modal) {
      removeScrollListener(); // also removes the 3-dot observer, so no load-more possible
    }
    else {
      scrollToStroredPosition(underlyingComponent)
      addScrollListener(underlyingComponent);
    }
  }
}

export function coveredComponentPositionProtector(modal, coveredComponent) {
  if (coveredComponent) {
    if (isDesktop()) {
       // not fully covered component
       setPageScroll(modal);
    }
    else {
      underlyingComponentPositionProtector(modal, coveredComponent);
    }
  }
}